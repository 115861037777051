import {useIntl} from 'react-intl';
import {Text} from '@chakra-ui/layout';

import {useTranslate} from '@eksab/i18n';
import {Image} from '@eksab/components/Image';

import type {PostRegistrationAction} from '../types';
import {CoinsIcon} from '@eksab/assets/icons';

interface RegistrationRewardProps {
  reward: PostRegistrationAction;
}

export function RegistrationReward({reward}: RegistrationRewardProps) {
  const format = useIntl().formatNumber;

  const t = useTranslate();

  return (
    <>
      <Image
        src="/animated/confirmed.gif"
        alt={t('auth.account_created')}
        width="160"
        height="160"
        w={[20, 40]}
        h="auto"
        mx="auto"
      />

      <Text fontWeight="800" fontSize="xl" mb="4">
        {t('auth.account_created')}
      </Text>

      <Text fontSize="lg" fontWeight="500" lineHeight="1.75">
        {t('auth.registration.reward.coins', {
          br: () => <br />,
          coins: (
            <Text as="strong" display="inline-flex" alignItems="center" gap="1" color="gold">
              <CoinsIcon h="4" w="auto" />

              {format(reward.coins)}
            </Text>
          ),
        })}
      </Text>
    </>
  );
}
