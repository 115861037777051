import {FormEventHandler, SetStateAction, Dispatch} from 'react';
import {useRouter} from 'next/router';
import {Text, InputGroup, InputLeftElement, Input, Button, Link as ChakraLink, Flex} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';
import {LeftChevronIcon, ProfileIcon} from '@eksab/assets/icons';

import {Background, Container} from './';

type SendResetCodeProps = {
  onSubmit: (identifier: string) => FormEventHandler<HTMLFormElement>;
  identifier: string;
  setIdentifier: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
};

export function SendResetCode({onSubmit, identifier, setIdentifier, isLoading}: SendResetCodeProps) {
  const {push} = useRouter();

  const t = useTranslate();

  return (
    <Background>
      <Container py="10">
        <Flex align="center" mb="1">
          <LeftChevronIcon width="5" p="0.5" cursor="pointer" onClick={() => push('/login')} />

          <Text fontWeight="800" mx="auto" fontSize="xl" lineHeight="1.8">
            {t('auth.reset_password')}
          </Text>
        </Flex>

        <Text fontSize="sm" lineHeight="1.7" mb="6" color="gray.800">
          {t('auth.reset-password.description')}
        </Text>

        <form onSubmit={onSubmit(identifier)}>
          <InputGroup mb="4">
            <InputLeftElement pointerEvents="none">
              <ProfileIcon width="5" />
            </InputLeftElement>

            <Input
              autoFocus
              placeholder={t('auth.email_or_number')}
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
            />
          </InputGroup>
          <Text fontSize="10" color="gray.500" textAlign="start" mb="2.5">
            {t('auth.recaptcha', {
              a1: (privacy) => (
                <ChakraLink
                  textDecor="underline"
                  _hover={{color: 'black'}}
                  color="unset"
                  href="https://policies.google.com/privacy?hl=en-GB"
                  fontSize="x-small"
                >
                  {privacy}
                </ChakraLink>
              ),
              a2: (terms) => (
                <ChakraLink
                  textDecor="underline"
                  _hover={{color: 'black'}}
                  color="unset"
                  href="https://policies.google.com/terms?hl=en-GB"
                  fontSize="x-small"
                >
                  {terms}
                </ChakraLink>
              ),
            })}
          </Text>

          <Button width="full" type="submit" disabled={isLoading || !identifier} isLoading={isLoading}>
            {t('auth.send_code')}
          </Button>
        </form>
      </Container>
    </Background>
  );
}
