import {AxiosError, AxiosResponse} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';

type Params = {
  identifier: string;
  otp: string;
  new_password: string;
};

export function useResetPasswordMutation(options?: UseMutationOptions<void, AxiosError, Params>) {
  return useMutation<void, AxiosError, Params>(
    (params) => api.post<Params, AxiosResponse<void>>('v2/reset-password', params).then((res) => res.data),
    {
      ...options,
      onSuccess: (...params) => {
        options?.onSuccess?.(...params);
      },
    },
  );
}
