import {Container, ContainerProps} from '@chakra-ui/react';

export function Background({children, ...props}: ContainerProps) {
  return (
    <Container
      variant="pattern-light"
      flexDir="column"
      w="100%"
      px={[0, null, 'unset']}
      py={[0, null, 14]}
      {...props}
      flex="1"
    >
      {children}
    </Container>
  );
}
