import {AxiosError, AxiosResponse} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';

import {api, setApiToken} from '@eksab/api';
import * as cache from '@eksab/cache';
import {useFetchMe, useSetAccessToken} from '@eksab/hooks';
import {config} from '@eksab/config';
import {useAuthStore} from '../state';

type Params = {
  identifier: string;
  password: string;
  region: Region;
};

type Return = {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
  extra: {
    additional: {
      region: Region;
    };
  };
};
export function useLoginMutation(options?: UseMutationOptions<Return, AxiosError, Params>) {
  const setLoggedIn = useAuthStore((state) => state.setLoggedIn);
  const setAccessToken = useSetAccessToken();
  const fetchMe = useFetchMe();
  return useMutation<Return, AxiosError, Params>(
    ({region, ...params}) =>
      api
        .post<Params, AxiosResponse<Return>>(
          '/api/login',
          {
            username: params.identifier,
            password: params.password,
            grant_type: 'password',
            client_id: '2',
            scope: 'user',
          },
          {
            baseURL: config.baseURL,
            headers: {
              'X-REGION': region,
            },
          },
        )
        .then((res) => res.data),
    {
      ...options,
      onSuccess: async (...params) => {
        mixpanel?.track('Login');
        try {
          setLoggedIn(true);
          setAccessToken(params[0].access_token);
          setApiToken(params[0].access_token);
          const me = await fetchMe();
          mixpanel?.identify(`${me.id}`);
          mixpanel?.people.set({name: me.name, email: me.email, coins: me.coins});
          cache.setMe(me);
          options?.onSuccess?.(...params);
        } catch (e) {
          console.error(e);
        }
      },
    },
  );
}
