import {SetStateAction, useEffect, Dispatch} from 'react';
import {useRouter} from 'next/router';
import {Button, Text, Flex} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';
import {LeftChevronIcon} from '@eksab/assets/icons';

import {Background, Container, OTPInput} from './';
import type {OTP} from '../types';

type EnterResetCodeProps = {
  goBack: () => void;
  onVerifyClicked: () => void;
  otp: OTP;
  setOtp: Dispatch<SetStateAction<OTP>>;
};

export function EnterResetCode({onVerifyClicked, goBack, otp, setOtp}: EnterResetCodeProps) {
  const router = useRouter();

  const t = useTranslate();

  useEffect(() => {
    const otp = router.query.otp as string | undefined;
    const otpCode = otp?.split('');

    if (otpCode?.length === 6) setOtp(otpCode as OTP);
  }, [router.query, setOtp]);

  return (
    <Background>
      <Container pb="10">
        <Flex align="center" mb="1">
          <LeftChevronIcon width="5" p="0.5" cursor="pointer" onClick={goBack} />

          <Text fontWeight="800" mx="auto" fontSize="xl" lineHeight="1.8">
            {t('auth.reset_password')}
          </Text>
        </Flex>

        <Text fontSize="xs" lineHeight="1.7" mb="8" color="gray.800">
          {t('auth.enter-reset-code')}
        </Text>

        <OTPInput otp={otp} setOtp={setOtp} mb="6" justifyContent="center" />

        <Button onClick={onVerifyClicked} disabled={!otp.every(Boolean)}>
          {t('auth.reset_password')}
        </Button>
      </Container>
    </Background>
  );
}
