import {AxiosError, AxiosResponse} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';

type Params = {
  phone_number: string;
};

export function useOTPMutation(options?: UseMutationOptions<void, AxiosError, Params>) {
  return useMutation<void, AxiosError, Params>(
    (params) =>
      api.post<Params, AxiosResponse<void>>('v3/profile/phone-number/send-otp', params).then((res) => res.data),
    {
      ...options,
      onSuccess: (...params) => {
        options?.onSuccess?.(...params);
      },
    },
  );
}
