import {useEffect} from 'react';
import {useRouter} from 'next/router';
import {Alert, AlertDescription, useDisclosure} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';

export const AuthenticationRequiredAlert = () => {
  const {query} = useRouter();

  const shouldOpen = 'from' in query;
  const {isOpen, onOpen} = useDisclosure({defaultIsOpen: shouldOpen});

  const t = useTranslate();

  useEffect(() => {
    if (shouldOpen) onOpen();
  }, [shouldOpen, onOpen]);

  return (
    <Alert
      status="warning"
      textAlign="center"
      justifyContent="center"
      py="0"
      h={isOpen ? [20, 16, 12] : 0}
      transition="height 0.5s"
    >
      <AlertDescription fontWeight="700">{t('app.protected.info')}</AlertDescription>
    </Alert>
  );
};
