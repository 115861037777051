import {useRouter} from 'next/router';
import NextLink from 'next/link';
import {Text, Image, Flex, Button} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';
import {useAppSettingsQuery} from '@eksab/hooks';

export function AccountCreated() {
  const router = useRouter();

  const t = useTranslate();
  const showBuyCoinsStep = useAppSettingsQuery().data?.buy_coins_after_sign_up;

  return (
    <>
      <Image src="/animated/confirmed.gif" alt={t('auth.account_created')} w={[20, 40]} h="auto" mx="auto" />

      <Text fontWeight="800" fontSize="xl" mb="1">
        {t('auth.account_created')}
      </Text>

      <Text fontSize={['xs', 'md']} mb="4">
        {t('auth.account_created_message')}
      </Text>

      {showBuyCoinsStep && (
        <Text fontSize={['xs', 'md']} color="gray.500" mb="5">
          {t('auth.register.buy-coins.description')}
        </Text>
      )}

      {showBuyCoinsStep && (
        <Flex flexDir={['column', 'row']} gap="2">
          <Button as={NextLink} href="/store" flex={['unset', 1]}>
            {t('app.actions.buy-coins')}
          </Button>

          <Button
            as={NextLink}
            href={decodeURIComponent((router.query.from as string | undefined) ?? '/')}
            variant="outline"
            flex={['unset', 1]}
          >
            {t('app.actions.skip')}
          </Button>
        </Flex>
      )}
    </>
  );
}
