import React from 'react';
import {BoxProps, Flex, Input, InputProps} from '@chakra-ui/react';

import {isNumeric} from '@eksab/util';

import {OTP} from '../types';

const baseProps: InputProps = {
  w: '3.125rem',
  h: 'full',
  sx: {aspectRatio: '1/1'},
  textAlign: 'center',
  inputMode: 'numeric',
  _hover: {},
  p: '0',
};

const BACKSPACE_KEY = 'Backspace' as const;

type InputContainer = {
  children: HTMLInputElement[];
} & HTMLDivElement;

type Props = {
  otp: OTP;
  setOtp: React.Dispatch<React.SetStateAction<OTP>>;
  disabled?: boolean;
} & BoxProps;

export function OTPInput({otp, setOtp, disabled, ...props}: Props) {
  const containerRef = React.useRef<InputContainer>(null);

  const onFocus = React.useCallback(() => {
    const focusIndex = otp.findIndex((v) => v === '');
    containerRef.current?.children[focusIndex === -1 ? otp.length - 1 : focusIndex].focus();
  }, [otp]);

  const onChange: (index: number) => React.ChangeEventHandler<HTMLInputElement> = (index) => (e) => {
    const {value} = e.target;
    if (!isNumeric(value)) return;
    const valueAsNumber: number = Number(value);
    if (valueAsNumber > 9 || valueAsNumber < 0) return;
    setOtp((old) => [...old.slice(0, index), value, ...old.slice(index + 1)] as OTP);
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === BACKSPACE_KEY) {
      const focusIndex = otp.findIndex((v) => v === '');
      if (focusIndex === 0) return;
      const deleteIndex = focusIndex === -1 ? otp.length - 1 : focusIndex - 1;
      setOtp((old) => [...old.slice(0, deleteIndex), '', ...old.slice(deleteIndex + 1)] as OTP);
    }
  };

  React.useEffect(() => {
    onFocus();
  }, [onFocus]);

  return (
    <Flex data-cy="otp-input" ref={containerRef} dir="ltr" gap={[2, null, 4]} w="full" {...props}>
      <Input {...baseProps} value={otp[0]} onChange={onChange(0)} onFocus={onFocus} onKeyDown={onKeyDown} />
      <Input {...baseProps} value={otp[1]} onChange={onChange(1)} onFocus={onFocus} onKeyDown={onKeyDown} />
      <Input {...baseProps} value={otp[2]} onChange={onChange(2)} onFocus={onFocus} onKeyDown={onKeyDown} />
      <Input {...baseProps} value={otp[3]} onChange={onChange(3)} onFocus={onFocus} onKeyDown={onKeyDown} />
      <Input {...baseProps} value={otp[4]} onChange={onChange(4)} onFocus={onFocus} onKeyDown={onKeyDown} />
      <Input {...baseProps} value={otp[5]} onChange={onChange(5)} onFocus={onFocus} onKeyDown={onKeyDown} />
    </Flex>
  );
}
