import {BoxProps} from '@chakra-ui/react';
import {Card} from '@eksab/components';
import {px} from '@eksab/theme';

export function Container({children, ...props}: BoxProps) {
  return (
    <Card
      textAlign="center"
      mx="auto"
      shadow={['none', null, 'md']}
      justifyContent="center"
      display="flex"
      flexDir="column"
      my="auto"
      pt="10"
      px={[...px.slice(0, 3), null]}
      pb="4"
      w={['100%', null, '480px']}
      flex={['1', null, 'unset']}
      {...props}
    >
      {children}
    </Card>
  );
}
