import {FormEvent, useState} from 'react';
import Joi from 'joi';
import {Button, Text, Flex, Input, InputGroup, InputLeftElement, InputRightElement, useToast} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';
import {LockIcon, ViewIcon, LeftChevronIcon} from '@eksab/assets/icons';

import {Background, Container} from './';

type ResetPasswordFormProps = {
  onSubmit: (new_password: string) => void;
  goBack: () => void;
  isLoading: boolean;
};

export function ResetPasswordForm({goBack, onSubmit, isLoading}: ResetPasswordFormProps) {
  const [password, setPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmationPasswordVisible, setConfirmationPasswordVisible] = useState(false);
  const toast = useToast();

  const t = useTranslate();

  const togglePasswordVisibility = () => setPasswordVisible((current) => !current);
  const toggleConfirmationPasswordVisibility = () => setConfirmationPasswordVisible((current) => !current);

  const schema = Joi.object({
    password: Joi.string().required(),
    confirmationPassword: Joi.any().valid(Joi.ref('password')).required(),
  });
  const {error} = schema.validate({password, confirmationPassword});

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (error) return toast({title: error.message, status: 'error'});

    onSubmit(password);
  };

  return (
    <Background>
      <Container pb="10">
        <Flex align="center" mb="10">
          <LeftChevronIcon width="5" p="0.5" cursor="pointer" onClick={goBack} />

          <Text fontWeight="800" mx="auto" fontSize="xl" lineHeight="1.8">
            {t('auth.reset_password')}
          </Text>
        </Flex>

        <form onSubmit={submit}>
          <InputGroup mb="2.5">
            <InputLeftElement pointerEvents="none">
              <LockIcon />
            </InputLeftElement>

            <Input
              id="password"
              type={passwordVisible ? 'text' : 'password'}
              placeholder={t('auth.new_password')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <InputRightElement>
              <ViewIcon cursor="pointer" opacity={passwordVisible ? 1 : 0.4} onClick={togglePasswordVisibility} />
            </InputRightElement>
          </InputGroup>

          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <LockIcon />
            </InputLeftElement>

            <Input
              id="confirmation-password"
              type={confirmationPasswordVisible ? 'text' : 'password'}
              placeholder={t('auth.confirm_new_password')}
              value={confirmationPassword}
              onChange={(e) => setConfirmationPassword(e.target.value)}
            />

            <InputRightElement>
              <ViewIcon
                cursor="pointer"
                opacity={confirmationPasswordVisible ? 1 : 0.4}
                onClick={toggleConfirmationPasswordVisibility}
              />
            </InputRightElement>
          </InputGroup>

          <Button width="full" type="submit" mt="5" disabled={isLoading || !!error} isLoading={isLoading}>
            {t('auth.reset')}
          </Button>
        </form>
      </Container>
    </Background>
  );
}
