import axios, {AxiosError, AxiosResponse} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';

import {setApiToken} from '@eksab/api';
import * as cache from '@eksab/cache';
import {useFetchMe, useSetAccessToken} from '@eksab/hooks';
import {config} from '@eksab/config';

type Params = {
  prizeCode: string;
  otp: string;
};

type Return = {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
  extra: {additional: {user_prize_id: number}};
};

export function usePrizeCodeLoginMutation(options?: UseMutationOptions<Return, AxiosError, Params>) {
  const setAccessToken = useSetAccessToken();
  const fetchMe = useFetchMe();
  return useMutation<Return, AxiosError, Params>(
    (params) =>
      axios
        .post<Params, AxiosResponse<Return>>(`${config.baseURL}/api/login`, {
          unique_prize_code: params.prizeCode,
          otp: params.otp,
          grant_type: 'unique-prize-code',
          scope: 'user',
          client_id: '2',
        })
        .then((res) => res.data),
    {
      ...options,
      onSuccess: async (...params) => {
        mixpanel?.track('Login');
        try {
          setAccessToken(params[0].access_token);
          setApiToken(params[0].access_token);
          const me = await fetchMe();
          mixpanel?.identify(`${me.id}`);
          mixpanel?.people.set({name: me.name, email: me.email, coins: me.coins});
          cache.setMe(me);
          options?.onSuccess?.(...params);
        } catch (e) {
          console.error(e);
        }
      },
    },
  );
}
