import {useRouter} from 'next/router';
import Joi from 'joi';

import {regionsPhonePrefix} from '@eksab/const';
import {useRegionSettings} from '@eksab/hooks/useRegionSettings';
import {useAppSettingsQuery} from '@eksab/hooks/useAppSettingsQuery';
import {useTranslate} from '@eksab/i18n/useTranslate';
import {getCountry} from '@eksab/util/helpers';

import type {RegisterParams} from './components/Register';

export const useValidateRegistrationForm = () => {
  const router = useRouter();

  const t = useTranslate();
  const phoneRegex = useAppSettingsQuery().data?.phone_number_regex ?? '';
  const isPhoneVerificationRequired = useRegionSettings().data?.settings.phone_number_verification_enabled;

  const countryCode = regionsPhonePrefix[getCountry(router.locale)];

  const schema = Joi.object({
    name: Joi.string()
      .required()
      .messages({base: t('auth.name-error')}),
    email: Joi.string()
      .empty('')
      .email({tlds: {allow: false}})
      .when('isPhoneVerificationRequired', {
        is: false,
        then: Joi.required().messages({base: t('auth.email-error')}),
      }),
    phone_number: Joi.string()
      .empty('')
      .pattern(new RegExp(phoneRegex))
      .when('isPhoneVerificationRequired', {
        is: true,
        then: Joi.required().messages({base: t('auth.phone-error')}),
      }),
    password: Joi.string()
      .required()
      .messages({base: t('auth.password-error')}),
    recaptcha: Joi.string().required(),
  });

  return (params: RegisterParams) =>
    schema.validate(
      {
        ...params,
        phone_number: params.phone_number ? `${countryCode}${params.phone_number}` : params.phone_number,
      },
      {
        context: {
          isPhoneVerificationRequired,
        },
      },
    ).error;
};

export function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}
