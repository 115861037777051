import {useEffect} from 'react';
import {create} from 'zustand';
import {createJSONStorage, persist} from 'zustand/middleware';

import type {PartnerCode} from '../types';

interface Partner {
  code: PartnerCode;
  name: `partners.partner.${PartnerCode}.name`;
  brand: `partners.partner.${PartnerCode}.brand`;
  logo: string;
}

export const partners = {
  gff: {
    code: 'gff',
    name: 'partners.partner.gff.name',
    brand: 'partners.partner.gff.brand',
    logo: '/images/partners/gff.webp',
  },
} as const satisfies Record<PartnerCode, Partner>;

const partnerCodes = Object.keys(partners) as PartnerCode[];

export const useSetPartnerReferral = () => {
  const setPartnerReferral = usePartnerReferralStore((state) => state.setPartnerReferral);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    const partnerCode = partnerCodes.find((code) => query.has(`${code}-code`));
    if (!partnerCode) return;

    const referralCode = query.get(`${partnerCode}-code`);
    if (!referralCode) return;

    setPartnerReferral(partnerCode, referralCode);
  }, [setPartnerReferral]);

  return null;
};

interface PartnerReferralStoreActions {
  setPartnerReferral: (partner: PartnerCode, referralCode: string) => void;
  reset: () => void;
}

type PartnerReferralStoreState =
  | {
      referralCode: null;
      partner: null;
    }
  | {
      referralCode: string;
      partner: Partner;
    };

type PartnerReferralStore = PartnerReferralStoreState & PartnerReferralStoreActions;

const initialState: PartnerReferralStoreState = {
  partner: null,
  referralCode: null,
};

export const usePartnerReferralStore = create<PartnerReferralStore>()(
  persist(
    (set) => ({
      ...initialState,
      setPartnerReferral: (partner, referralCode) =>
        set({
          referralCode,
          partner: partners[partner],
        }),
      reset: () => set(initialState),
    }),
    {
      name: 'partner-referral',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
