export function sendTwitterEvent(eventId: string, payload: object) {
  twq?.('event', eventId, payload);
}

export function sendTwitterCompeteteRegistrationEvent(user: {id: number; email: string; phone_number: string}) {
  sendTwitterEvent('tw-o71rv-ogpg0', {
    conversion_id: user.id,
    email_address: user.email,
    phone_number: user.phone_number,
  });
}

export function sendTwitterOrderCompletedEvent(purchase: Purchase) {
  sendTwitterEvent('tw-o71rv-ogjtx', {
    value: purchase.package.price.total,
    currency: purchase.currency,
    contents: [
      {
        content_type: 'Package',
        content_id: purchase.package.id,
        content_name: null,
        content_price: purchase.package.price.total,
        num_items: 1,
        content_group_id: null,
      },
    ],
    email_address: purchase.user.email,
    phone_number: purchase.user.phone_number,
  });
}
